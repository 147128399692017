import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import AnalyticsProviderContext from './AnalyticsProviderContext.js';
import { ANALYTICS_FLUSH_INTERVAL, ANALYTICS_MAX_EVENTS, ANALYTICS_VISITOR_ID_KEY } from './settings.js';
import useDocumentVisibility from '../../services/useDocumentVisibility/index.js';

const events = [];

export default function AnalyticsProvider({ children }) {
  const location = useLocation(),
        visibility = useDocumentVisibility(),
        previousLocation = useRef({}),
        openedModal = useRef({});

  function trackLocationChange() {
    if (previousLocation.current.name) {
      track('navigation', previousLocation.current.name, { start: previousLocation.current.start, end: Date.now() });
    }

    previousLocation.current = {
      start: Date.now(),
      name: location.pathname
    };
  }

  function trackVisibilityChanges() {
    if (visibility === 'visible') {
      track('lifecycle', 'start');
    } else {
      if (previousLocation.current.name) {
        track('navigation', previousLocation.current.name, { start: previousLocation.current.start, end: Date.now() });
      }

      track('lifecycle', 'end');
      flushTrackedEvents();

      previousLocation.current = {};
    }
  }

  function flushTrackedEventsRegularly() {
    const interval = setInterval(flushTrackedEvents, ANALYTICS_FLUSH_INTERVAL);

    return function clearFlushInterval() {
      return clearInterval(interval);
    };
  }

  function trackModals() {
    function isDialog(node) {
      return node?.classList.contains('MuiDialog-root');
    }

    function callback(mutationsList) {
      for (const mutation of mutationsList) {
        const [addedNode] = mutation.addedNodes,
              [removedNode] = mutation.removedNodes;

        if (isDialog(addedNode)) {
          openedModal.current = {
            start: Date.now(),
            name: addedNode.querySelector('.MuiDialogTitle-root')?.firstChild?.textContent
          };
        } else if (isDialog(removedNode)) {
          track('modal', openedModal.current.name, { start: openedModal.current.start, end: Date.now() });

          openedModal.current = {};
        }
      }
    }

    const observer = new MutationObserver(callback);

    observer.observe(document.body, {
      childList: true,
      attributes: false,
      subtree: false,
      characterData: false
    });

    return function stopTrackingModals() {
      observer.disconnect();
    };
  }

  function trackJavascriptErrors() {
    function trackError(event) {
      track('error', event.message, { details: event.error?.stack });
    }

    window.addEventListener('error', trackError);

    return function stopTrackingJavascriptErrors() {
      window.removeEventListener('error', trackError);
    };
  }

  useEffect(trackVisibilityChanges, [visibility]);
  useEffect(trackLocationChange, [location]);
  useEffect(trackModals, []);
  useEffect(trackJavascriptErrors, []);
  useEffect(flushTrackedEventsRegularly, []);

  return (
    <AnalyticsProviderContext value={track}>
      {children}
    </AnalyticsProviderContext>
  );
}

//

function track(type, name, options) {
  events.push({ type, name, start: Date.now(), url: window.location.href, ...options });
}

export function flushTrackedEvents() {
  let visitorId = localStorage.getItem(ANALYTICS_VISITOR_ID_KEY);

  if (!visitorId) {
    localStorage.setItem(ANALYTICS_VISITOR_ID_KEY, visitorId = crypto.randomUUID());
  }

  while (events.length) {
    const chunk = events.slice(-ANALYTICS_MAX_EVENTS);

    navigator.sendBeacon('/api/track', JSON.stringify({
      visitorId,
      referrer: document.referrer,
      width: window.screen.width,
      height: window.screen.height,
      agent: navigator.userAgent,
      events: chunk
    }));
    events.length -= chunk.length;
  }
}
