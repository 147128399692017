import { useLocation, Link as RouterLink } from 'react-router';
import Link from '@mui/material/Link';

export default function ClientSideLink({ to, hash = '', children, disabled, ref, ...rest }) {
  const { search } = useLocation();

  if (disabled) {
    return children;
  }

  return (
    <Link ref={ref} component={RouterLink} to={to + search + hash} {...rest}>
      {children}
    </Link>
  );
}
