/**
 * All supported sampling intervals.
 * Associates the actual Elasticsearch interval and the equivalent in minutes (the smallest granularity we have)
 *
 * @type {[interval: string, minutes: number][]}
 */
module.exports = [
  ['5m', 5],
  ['10m', 10],
  ['15m', 15],
  ['30m', 30],
  ['1h', 60],
  ['2h', 60 * 2],
  ['3h', 60 * 3],
  ['6h', 60 * 6],
  ['12h', 60 * 12],
  ['1d', 60 * 24],
  ['1w', 60 * 24 * 7],
  ['1M', 60 * 24 * 31],
  ['1y', 60 * 24 * 365]
];
