import StordataLogo from '@stordata/cmdb-common/src/components/StordataLogo/index.jsx';

import styles from './Header.module.scss';

export default function Header() {
  return (
    <div className={styles.logo}>
      <StordataLogo large />
    </div>
  );
}
