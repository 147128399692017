import Tabs from '@mui/material/Tabs';
import { useContext } from 'react';

import TabContext from '../TabContext/index.jsx';

export default function TabList({ onChange, className, children, ref }) {
  return (
    <Tabs ref={ref} value={useContext(TabContext)} onChange={onChange} className={className}>
      {children}
    </Tabs>
  );
}
