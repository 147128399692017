import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import backend from '@stordata/cmdb-common/src/services/backend/index.js';
import ButtonBar from '@stordata/cmdb-common/src/components/ButtonBar/index.jsx';
import useApplicationContext from '@stordata/cmdb-common/src/services/useApplicationContext/index.js';

import styles from './PasswordResetForm.module.scss';

export default function PasswordResetForm() {
  const navigate = useNavigate(),
        { t: __ } = useTranslation(),
        { register, handleSubmit, formState: { errors, isSubmitting }, setError, clearErrors } = useForm(),
        { showMessage } = useApplicationContext();

  function sendPasswordReset(data) {
    return backend.post('/resetpassword', data)
      .then((res) => {
        clearErrors('email');
        showMessage(__("We've sent you an email"));

        return res;
      })
      .catch((err) => {
        const { response } = err;

        if (response.data && response.data.error) {
          setError('email', {
            type: 'manual',
            message: response.data.error
          });
        }

        return err;
      });
  }

  return (
    <Card className={styles.form}>
      <form onSubmit={handleSubmit(sendPasswordReset)}>
        <CardContent>
          <div className={styles.title}>
            <Typography variant="h5">{__('Reset your password')}</Typography>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={styles.helper}>
                <Typography variant="caption">{__('In order to reset your password please enter the email address of your account in the field below')}</Typography>
              </div>
              <TextField error={errors.email && true} helperText={errors.email?.message} data-testid="email" inputProps={register('email')} type="email" label={__('Email')} fullWidth required />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <ButtonBar>
            <Button onClick={() => navigate(-1)}>{__('Back')}</Button>
            <Button data-testid="submit" type="submit" disabled={isSubmitting}>{__('Send')}</Button>
          </ButtonBar>
        </CardActions>
      </form>
    </Card>
  );
}
