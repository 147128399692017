import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import backend from '@stordata/cmdb-common/src/services/backend/index.js';

export default function ForgetMeConfirmation() {
  const { t: __ } = useTranslation();

  useEffect(() => { backend.get('/api/users/me/forget/confirm'); }, []);

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{__('Your account removal has been registered by Stordata Team.')}</Typography>
      </CardContent>
    </Card>
  );
}
