import { useEffect, useState } from 'react';

export default function useDocumentVisibility() {
  const [state, setState] = useState(document.visibilityState);

  function listenForVisibilityChanges() {
    function handleVisibilityChange() {
      setState(document.visibilityState);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return function stopListeningForVisibilityChanges() {
      return document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }

  useEffect(listenForVisibilityChanges, []);

  return state;
}
