import cx from 'classnames';
import { useContext } from 'react';

import TabContext from '../TabContext/index.jsx';

import styles from './TabPanel.module.scss';

export default function TabPanel({ value, className, children, ref }) {
  const isActive = useContext(TabContext) === value;

  return (
    <div ref={ref} role={'tabpanel'} hidden={!isActive} className={cx(styles.panel, className)}>
      {isActive && children}
    </div>
  );
}
