import { useEffect, useMemo } from 'react';

import HotkeysContext from './HotkeysContext.jsx';
import HotkeyRecap from './HotkeyRecap/index.jsx';
import useTenantConfiguration from '../../services/useTenantConfiguration/index.js';

/**
 * The registry of global hotkeys
 *
 * @type {{ hotkey: Hotkey, handler: function(KeyboardEvent): void, description: [string, string] }[]}
 */
const registry = [];

/**
 * Registers a hotkey globally.
 * The Hotkey object instance must be created beforehand, typically using a static constant of the Hotkey class.
 *
 * @param hotkey {Hotkey} The hotkey to register
 * @param handler {function(KeyboardEvent): void} The handler to run when the hotkey triggers
 * @param [description] {[string, string]} A description of the action performed by this hotkey. The format is an array of
 *  two elements, the first one being the hotkey unique identifier and the second one being the actual description.
 *
 * @returns {(function(): void)} A function that unregisters the listener
 */
function register(hotkey, handler, description) {
  registry.push({ hotkey, handler, description });

  return function unregister() {
    for (let i = 0; i < registry.length; i++) {
      if (registry[i].hotkey === hotkey) {
        registry.splice(i, 1); // Removes item in place
      }
    }
  };
}

/**
 * Used to filter some keyboard events, based on various criteria such as the focused HTML tag, etc.
 *
 * @param e {KeyboardEvent} The event to check
 *
 * @returns {boolean} Whether the event should be ignored
 */
function isEventIgnored(e) {
  return e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || e.target.getAttribute('contenteditable') === 'true';
}

function registerGlobalListener() {
  function listener(e) {
    if (isEventIgnored(e)) {
      return;
    }

    for (const { hotkey, handler } of registry) {
      if (hotkey.matches(e)) {
        handler();
        e.preventDefault();
      }
    }
  }

  document.addEventListener('keydown', listener);

  return function unregisterGlobalListener() {
    document.removeEventListener('keydown', listener);
  };
}

/**
 * Triggers the action registered for a particular Hotkey description.
 * Only the first one will be triggered, as hotkeys might have secondary actions registered.
 *
 * @param what {[string, string]} The hotkey description to trigger
 * @param [options] {object} An object holding custom options for the triggered action
 */
function trigger(what, options) {
  for (const { handler, description } of registry) {
    if (what[0] === description?.[0]) { // Only match the Hotkey ID, as the description can vary depending on the context
      return handler(options);
    }
  }
}

export default function HotkeysProvider({ children }) {
  const value = useMemo(() => ({ register, trigger }), []),
        { userMenu: { disableHotkeys } } = useTenantConfiguration();

  useEffect(registerGlobalListener, []);

  if (disableHotkeys) {
    return children;
  }

  return (
    <HotkeysContext value={value}>
      {children}
      <HotkeyRecap registry={registry} />
    </HotkeysContext>
  );
}
