import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import TabPanel from '@stordata/cmdb-common/src/components/TabPanel/index.jsx';
import TabContext from '@stordata/cmdb-common/src/components/TabContext/index.jsx';
import TabList from '@stordata/cmdb-common/src/components/TabList/index.jsx';
import ClientSideLink from '@stordata/cmdb-common/src/components/ClientSideLink/index.jsx';
import useTenantConfiguration from '@stordata/cmdb-common/src/services/useTenantConfiguration/index.js';

import Contacts from '../Contacts/index.jsx';
import LoginForm from '../LoginForm/index.jsx';
import SignupForm from '../SignupForm/index.jsx';

import styles from './LoginTabs.module.scss';

export default function LoginTabs() {
  const { t: __, i18n: { resolvedLanguage } } = useTranslation(),
        { loginApp: { disableSignup, disableContact, welcomeTo } } = useTenantConfiguration();

  return (
    <Card>
      <CardContent>
        <div className={styles.title}>
          <Typography variant={'h5'}>{welcomeTo[resolvedLanguage]}</Typography>
        </div>
        <TabContext value={useLocation().pathname}>
          <TabList>
            <Tab label={__('Login')} component={ClientSideLink} data-testid={'login'} to={'/'} value={'/'} />
            {!disableSignup && <Tab label={__('Sign up')} component={ClientSideLink} data-testid={'signup'} to={'/signup'} value={'/signup'} />}
            {!disableContact && <Tab label={__('Contact')} component={ClientSideLink} data-testid={'contact'} to={'/contact'} value={'/contact'} />}
          </TabList>
          <TabPanel value={'/'}>
            <LoginForm />
          </TabPanel>
          {!disableSignup && (
            <TabPanel value={'/signup'}>
              <SignupForm />
            </TabPanel>
          )}
          {!disableContact && (
            <TabPanel value={'/contact'}>
              <Contacts />
            </TabPanel>
          )}
        </TabContext>
      </CardContent>
    </Card>
  );
}
