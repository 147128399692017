import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import queryClient from '../queryClient/index.jsx';
import useConfig from '../useConfig/index.js';

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector);

export const LANGUAGE_FR = 'fr';
export const LANGUAGE_EN = 'en';

export const SUPPORTED_LANGUAGES = [
  [LANGUAGE_FR, 'Français'],
  [LANGUAGE_EN, 'English']
];

const I18N_NS_DEFAULT = 'core';

export function useInitializedI18n(lng) {
  const { config } = useConfig();

  if (!i18n.isInitialized) {
    throw i18n.init({ // Throwing to trigger suspense
      lng,
      ns: [I18N_NS_DEFAULT, ...config.PLUGINS],
      defaultNS: I18N_NS_DEFAULT,
      fallbackNS: config.PLUGINS,
      fallbackLng: false, // Avoids loading two language files on initial load
      supportedLngs: SUPPORTED_LANGUAGES.map(([language]) => language), // What we support
      nonExplicitSupportedLngs: true, // Supports "language-COUNTRY" as "language"
      load: 'languageOnly', // Do not try to load "language-COUNTRY" translation files, we don't have these
      keySeparator: false,
      nsSeparator: false,
      interpolation: {
        prefix: '%(',
        suffix: ')s',
        escapeValue: false
      },
      backend: {
        loadPath: (unused, [ns]) => {
          if (ns === I18N_NS_DEFAULT) { // Use the unscoped (no namespace) translations when i18next requests the core namespace
            return '/locales/%(lng)s.json';
          }

          return '/locales/plugins/%(ns)s/%(lng)s.json';
        },
        request: (options, url, payload, callback) => {
          queryClient.fetchQuery({ queryKey: [url] })
            .then(data => callback(null, { status: 200, data }))
            .catch(callback);
        }
      },
      detection: {
        order: ['querystring', 'navigator'], // Disable all other unused language detectors
        caches: false // Caching prevents a PDF report from overriding the locale
      }
    });
  }

  /**
   * Under react@19, the i18next instance is initialized but the language isn't changed.
   * Triggering a changeLanguage() in this case is mandatory or the resolved language is not defined and the app breaks.
   *
   * Subsequent language changes _do not_ trigger a suspense (proof in unit tests) despite the throw below.
   */
  if (!i18n.resolvedLanguage || (lng && i18n.language !== lng)) {
    throw i18n.changeLanguage(lng);
  }

  return i18n;
}
