import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import navigateTo from '@stordata/cmdb-common/src/services/navigateTo/index.js';
import InfoBox from '@stordata/cmdb-common/src/components/InfoBox/index.jsx';
import useMe from '@stordata/cmdb-common/src/services/useMe/index.js';
import LocalizedMarked from '@stordata/cmdb-common/src/components/LocalizedMarked/index.jsx';
import OTPQRCode from '@stordata/cmdb-common/src/components/OTPQRCode/index.jsx';
import ButtonBar from '@stordata/cmdb-common/src/components/ButtonBar/index.jsx';

import useReturnTo from '../../services/useReturnTo/index.js';

import styles from './OTPForm.module.scss';

export default function OTPForm() {
  const { t: __ } = useTranslation(),
        [returnTo] = useReturnTo(),
        { register, handleSubmit, formState: { errors, isSubmitting }, setError } = useForm(),
        { me: { '2FA': is2FAAlreadySetup }, enableUser2FA } = useMe();

  function sendOTPCode(data) {
    return enableUser2FA(data)
      .then(() => navigateTo(returnTo))
      .catch(() => setError('code', { message: __('Invalid code') }));
  }

  return (
    <Card className={styles.form}>
      <form onSubmit={handleSubmit(sendOTPCode)} data-testid="form">
        <CardContent>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <div className={styles.centered}>
                <Typography variant="h5">{__('OTP Login')}</Typography>
              </div>
            </Grid>
            <Grid item>
              <InfoBox>
                <LocalizedMarked folder={is2FAAlreadySetup ? '2FALogin' : '2FARequired'} />
              </InfoBox>
            </Grid>
            <Grid item hidden={is2FAAlreadySetup}>
              <div className={styles.centered}>
                <OTPQRCode />
              </div>
            </Grid>
            <Grid item>
              <TextField inputProps={register('code')} error={!!errors.code} helperText={errors.code?.message} type="text" label={__('Code')} fullWidth required />
            </Grid>
            <div className={styles.centered}>
              <ButtonBar>
                <Button data-testid="submit" variant={'contained'} type="submit" disabled={isSubmitting}>{__('Send')}</Button>
              </ButtonBar>
            </div>
          </Grid>
        </CardContent>
        <CardActions>
          <div className={styles.centered}>
            <Link href="https://en.wikipedia.org/wiki/Multi-factor_authentication" rel={'noopener'} target="_blank">{__('Get more information on two factor authentification')}</Link>
          </div>
        </CardActions>
      </form>
    </Card>
  );
}
