import qs from 'query-string';
import { QueryParamProvider } from 'use-query-params';

import ReactRouterAdapter from './ReactRouterAdapter/index.jsx';

const USE_QUERY_PARAMS_OPTIONS = {
  enableBatching: true,
  objectToSearchString: qs.stringify,
  searchStringToObject: qs.parse
};

export default function UseQueryParamsProvider({ children }) {
  return (
    <QueryParamProvider adapter={ReactRouterAdapter} options={USE_QUERY_PARAMS_OPTIONS}>
      {children}
    </QueryParamProvider>
  );
}
