import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import useRouteTo from '@stordata/cmdb-common/src/services/useRouteTo/index.js';
import backend from '@stordata/cmdb-common/src/services/backend/index.js';
import PasswordCriteria from '@stordata/cmdb-common/src/components/PasswordCriteria/index.jsx';
import ButtonBar from '@stordata/cmdb-common/src/components/ButtonBar/index.jsx';
import ClientSideLink from '@stordata/cmdb-common/src/components/ClientSideLink/index.jsx';

import useJWT from '../../services/useJWT/index.js';

import styles from './SignupConfirmForm.module.scss';

export default function SignupConfirmForm() {
  const [jwt] = useJWT(),
        { t: __ } = useTranslation(),
        { register, handleSubmit, formState: { errors, isSubmitting }, setError } = useForm(),
        routeTo = useRouteTo(),
        [genericError, setGenericError] = useState();

  function confirmSignup(data) {
    return backend.post('/confirm', data)
      .then(() => routeTo('/', { reason: 'signup' }))
      .catch((err) => {
        const error = err.response?.data.error,
              parameter = error?.err?.parameter;

        if (error?.err?.name === 'FragilePasswordError') {
          return setError(parameter, {
            type: 'manual',
            message: __(error.message)
          });
        }

        if (parameter === 'passwordConfirm') {
          return setError(parameter, {
            type: 'manual',
            message: __('This field is invalid')
          });
        }

        setGenericError(error);
      });
  }

  return (
    <Card className={styles.form}>
      <form onSubmit={handleSubmit(confirmSignup)}>
        {genericError && <Alert severity="error">{__(genericError.message)}</Alert>}
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs>
              <div className={styles.centered}>
                <Typography variant="h5">{__('Confirm your registration')}</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField inputProps={register('firstname')} label={__('firstname')} fullWidth required />
            </Grid>
            <Grid item xs={12}>
              <TextField inputProps={register('lastname')} label={__('lastname')} fullWidth required />
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={<PasswordCriteria />} placement={'right'} arrow>
                <TextField inputProps={register('password')} error={!!errors.password} helperText={errors.password?.message} type="password" label={__('Password')} fullWidth required />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={<PasswordCriteria />} placement={'right'} arrow>
                <TextField inputProps={register('passwordConfirm')} error={!!errors.passwordConfirm} helperText={errors.passwordConfirm?.message} type="password" label={__('Confirm password')} fullWidth required />
              </Tooltip>
            </Grid>
            <input {...register('jwt')} type="hidden" value={jwt} />
          </Grid>
        </CardContent>
        <CardActions>
          <ButtonBar>
            <ClientSideLink to={'/'}>{__('i already have an account')}</ClientSideLink>
            <Button data-testid="submit" type="submit" disabled={isSubmitting}>{__('Confirm')}</Button>
          </ButtonBar>
        </CardActions>
      </form>
    </Card>
  );
}
